import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link ,useNavigate } from "react-router-dom";
import axios from 'axios';
import './Header.css'
import './mystyle.css';

const Header = ({ navigation }) => {
    const navigate = useNavigate()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);
    const [styleData, setStyleData] = useState(null);
    const [admin, setadmin] = useState(false)
    const [navLinks, setNavLinks] = useState([]);

  

    const handleLinkClick = () => {
        setMobileMenuOpen(false); // Close the mobile menu when a link is clicked
    };

	const handleClickbtn=()=>{
        navigate("/sell")
    }

    useState(() => {
        const admin = localStorage.getItem("admin");
        const loginpassed = localStorage.getItem("user")
        if (admin === "%@#^#%&^%^&$%@@^@%$%^,^$#@@#8757636345^$%^$%&32422" && loginpassed === "76342391251#@%#^%(^%&^$$%#@$%448941/-++y$#%$^^&^$@") {
            setadmin(true)
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/navigation');
                setNavLinks(response.data);
            } catch (error) {
                console.error('Error fetching navigation:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        axios.get('/api/getnavstyles').then((res) => {
            setStyleData(res.data[0]); // Assuming you get an array, and you need the first item
        });
    }, []);


    const HeaderContainer = styled.header`
    background-color: ${styleData ? styleData.header_background_color : '#fff'};
    color: white;
    height:82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 4rem;
   margin: auto;
  width: 100%;
    `;

    const Brand = styled.h1`
        margin: 0;
        font-size: 1.5rem;
        color: #f8f9fa;
    `;

    const Nav = styled.nav`
    @media (max-width: 1026px) { // Change from max-width: 768px to max-width: 1024px
        display: ${props => props.open ? 'block' : 'none'};
		line-height: 2;
        position: absolute;
        top: 82px;
        left: 0;
        right: 0;
        background-color: #343a40;
        padding: 1rem;
        z-index: 100;

        a {
            display: block;
            margin: 0.5rem 0;
            color: white;
        }
    }

    @media (min-width: 1027px) { // Ensure it's above 1024px
        display: flex;
       

    }

`;


    const NavLink = styled.a`
    color: ${styleData ? styleData.nav_color : '#f8f9fa'};
    font-size:${styleData ? styleData.nav_font_size : '16px'};
    text-decoration: none;
    padding: 0.5rem 1rem;
    
    &:hover {
        text-decoration: underline;
    }
    `;

    const StyledButton = styled.button`
    color: ${props => props.textColor};
    background-color: transparent;
    font-size: ${props => props.fontSize};
	border: 1px solid #212529;
    display: block;
	transition: background-color 0.3s, color 0.3s;
	
	 &:hover {
	 background-color: #504d4f;
	 color: #fff;
      border-color: transparent;
    }

    @media (max-width: 1026px) {
        display: none;
    }
  
`;



    const MenuButton = styled.button`
    background: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    font-size: ${props => props.fontSize};
    cursor: pointer;
    display: none; // Initially hide the button
    border: 1px solid rgb(201, 199, 199);

  
    
    
    @media (max-width: 1026px) {
        display: block; // Show the button when screen width is 1024px and below
        position: absolute;
        top: 0;
        right: 20px; // Adjust this value as needed
        margin-top:20px;
// padding: 7px 10px;
font-size:22px;
        background:transparent;
    }

    @media (min-width: 364px) and (max-width: 600px) {
        .uiefy {
            border: 1px solid red; // Apply red color border to class uiefy
        }
    }
   
`;

    const [sliceval,setSliceVal]=useState('');
    useEffect(() => {
        function handleResize() {
            const screenWidth = window.innerWidth;
            let newSliceVal = 6; // Default slice end index
            if (screenWidth >= 1025 && screenWidth <= 1200) {
                
                newSliceVal = 5;
            }
            setSliceVal(newSliceVal);
        }

        // Initial call to set slice value based on initial screen width
        handleResize();

        // Add event listener to handle screen width changes
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [/* no dependencies */]); 
    return (
        <HeaderContainer id='main-navbar-responsive-view' style={{position: "fixed", zIndex: "999", width:"100%"}}>

            {styleData && styleData.logo && (
                <Link to="/">
                    <img src={`uploads/${styleData.logo}`} alt="Logo" width="215px" className='mainLogo' />
                </Link>
            )}
            <div className='gywf'>
                <MenuButton
                    backgroundColor={styleData ? styleData.buttonBackgroundColor : '#f8f9fa'}
                    textColor={styleData ? styleData.buttonTextColor : '#000000'}
                    fontSize={styleData ? styleData.buttonFontSize : '16px'}
                    onClick={toggleMobileMenu}
                    style={{ height: "30px", width: "40px", marginLeft: "20%", padding: "0px 37px 37px 17px" }}
                    className='uiefy'>
                    ☰
                </MenuButton>
            </div>
            <Nav open={mobileMenuOpen} className="target-wid">
                {mobileMenuOpen
                    ? navLinks.map((item, index) => {
                        return (
                            <>
                                <Link key={index} to={item.urls} onClick={handleLinkClick}  className='navigation_links change-font' style={{ textDecoration: "none" }}>
                                    {item.title}
                                </Link>
                            </>
                        )
                    }
                    )
                    : navLinks.slice(0, sliceval).map((item, index) => {
                        if (item.urls === "https://www.rhvacations.ae ") {
                            return (
                                <>
                                    <Link key={index} className="change-font" to={item.urls} style={{ textDecoration: "none" }}>
                                        {item.title}
                                    </Link>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <Link key={index} to={item.urls} className="change-font" target={item.openin === "newwindow" ? "_blank" : "_self"} style={{ textDecoration: "none", padding:"0.5rem 0.6rem", color: "383838", fontSize: "18px"}}>
                                        {item.title}
                                    </Link>
                                </>
                            )
                        }
                    })
                }

                {navLinks.length > 6 && (
                    <div className="dropdown mobno" >
                        <button onClick={toggleDropdown} className="change-font" style={{ color: "rgb(56, 56, 56) !important" }}>Explore More <span class="dropdown-arrow "><img className="arrow-header" src="/images/arrow-icon.svg" alt="" /></span></button>
                        {dropdownOpen && (
                            <div className="dropdown-content" >
                                {navLinks.slice(6).map((item, index) => (
                                    <Link key={index + 6} to={item.urls} className="change-font" target={item.openin === "newwindow" ? "_blank" : "_self"} style={{ textDecoration: "none"}}>
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                )}

            </Nav>

            <div className="header-button mobno">
                <StyledButton
                    textColor={styleData?.buttonTextColor}
                    backgroundColor={styleData?.buttonBackgroundColor}
                    fontSize={styleData?.buttonFontSize}
					onClick={handleClickbtn}
                >
                    {styleData ? styleData.button_text : 'List Your Property'}
                </StyledButton>
            </div>

        </HeaderContainer>
    );
};


export default Header;