import React, {useContext, useState} from "react";
import { Navigate, Outlet } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SearchContext from "../../Context/Context1";
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from "axios";



const Private = () => {
  const getdatafromContext =useContext(SearchContext);
  const [gettxt,settxt]=useState('');
  const auth = localStorage.getItem("user");
  const [show, setShow] = useState(false);

	
	  const callPropertiesApi =async()=>{
    await axios.get("/api/data").then((res)=>{
      console.log(res.data,"response from server");
      if(res){
        alert("Data is updated!")
      }
    })
  }
  const handleClose = () => setShow(false);
   
  

  const handleLogout = () => {


    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    window.location.href = "/";
    return <Navigate to="/" />;
  };





  if (auth === "76342391251#@%#^%*(^%&^$$%#@$%448941/*-++y$#%$^^&^*$@") {
    return (
      <>
        
			<div style={{height:"100px"}}></div>
      <div style={{float:"right", marginRight:"100px", height:"50px", display:"flex",gap:10}}>

          <button
            // style={{ marginRight: "80.5%", marginBottom: "10px" }}
            onClick={handleLogout}
            className="btn btn-danger"
          >
            Logout
          </button>
          <button
			  style={{background:"#198754 !important"}}
            // style={{ marginLeft: "88.5%", marginBottom: "-44px" }}
            onClick={callPropertiesApi}
            className="btn btn-success"
          >
            Update Properties
          </button>
          
      </div>
      
        <Outlet />
      </>
    );
  } else {
    // Perform logout when the user is not authenticated
    handleLogout();
    return <Navigate to="/" />;
  }
};

export default Private;
