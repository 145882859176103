import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselComponents = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <div className="show-on-mobiles" style={{  position: 'relative',width:"100%",margin:"auto"  }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={`${image}`} alt={`Slide ${index}`} style={{ width: '100%', height: '280px',objectFit: "inherit" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'transparent', // Remove background color
        right: '10px',
        zIndex: 1,
        color: '#f7f7f7', // Change arrow color
        borderRadius: 0 // Remove border radius
      }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'transparent', 
        left: '10px',
        zIndex: 1,
        color: '#f7f7f7', 
        borderRadius: 0 
      }}
      onClick={onClick}
    />
  );
};

export default CarouselComponents;