import React, { useEffect, useState, useContext, useRef, lazy, Suspense } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

const HomeForm = React.lazy(() => import('./HomeForm'));
const HomeSlider = React.lazy(() => import('./HomeSlider'));
const HomeBlog = React.lazy(() => import('./HomeBlog'));
const Testomonial = React.lazy(() => import('./home-testimonials/Testimonial'));

import "../styles/main/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import "react-phone-input-2/lib/style.css";

import SearchContext from "../Context/Context1";
import Countries from "./Notification/CountryInfo";
import '../styles/main/sale.css'

import { useNavigate } from "react-router";
import { Dropdown } from "react-bootstrap";
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { FaAngleDown } from 'react-icons/fa'
import { MdOutlineKingBed } from "react-icons/md";
import { PiBathtub } from "react-icons/pi";
import { RxDimensions } from "react-icons/rx";
import Swal from 'sweetalert2';
//import HomeBlog from "./HomeBlog";



const WhatsappChat = lazy(() => import("./Notification/Whatapp"));
import Popup from "./Notification/Popup";






const Main = () => {
 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectdrop, setselectdrop] = useState("Sale");
  const [place, setPlace] = useState("");
  
	
	const [addressdata, setAddressdata]=useState([]);
  
  const navigate = useNavigate()
  


  const [value, setvalue] = useState("Sale");
  const getmydata = useContext(SearchContext);
  const [jsonData, setJsonData] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const [activeButton, setActiveButton] = useState("Sale");
  const [isChecked, setIsChecked] = useState(false);
  const [alldata, setalldata] = useState([]);
  const [filterLocation, setFilterLocation] = useState("");
const [propChange,setPropChange]=useState('');

  const createMarkup = (html) => ({ __html: html });
  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  // ****************Romove Html Tags******************
  function removeHtmlTags(html) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  function extractTextFromHtml(html, elementTag) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    var element = doc.querySelector(elementTag);
    return element ? removeHtmlTags(element.innerHTML) : "";
  }

  // ****************Romove Html Tags End******************


  const handlefieldChange = (e) => {

    setFilterLocation(e.target.value)
  }
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const [subemail, setSubemail] = useState("");
 
  function renderWithLineBreaks(text) {
    // Replace <br/> with \n
    const replacedText = text.replace(/<br\s*\/?>/g, '\n');
    // Split the text by newline character and map each part to a <p> element
    const paragraphs = replacedText.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
    return paragraphs;
  }
  
    const [isValidEmail, setIsValidEmail] = useState(true);
	

  const handleEmailChange = (e) => {
    const subemail = e.target.value;
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(subemail));
    setSubemail(subemail);
  };
  


  function SearchFilter(e) {
    e.preventDefault();
    getmydata.setIssearch(true);
    getmydata.setloc(filterLocation);
    if (selectdrop === 'Sale') {
      const buy = "buy"
      localStorage.setItem("whichpage", buy)
      navigate('/buy')
    }
    else if (selectdrop === 'Rent') {
      const rent = "rent"
      localStorage.setItem("whichpage", rent)
      navigate('/rent')
    }
    else {
      navigate('/buy')
    }
  }



  function GoTOAboutUS() {
	  window.open('/aboutus');
  
  }

 const [visited, setVisited] = useState(false);
const [checked,setChecked]=useState("false");
  const [popupShown, setPopupShown] = useState(false);

useEffect(() => {
	//console.log(localStorage.getItem("popupShown"));
    const popstats = localStorage.getItem("popupShown") === "true";
  
    if (!popstats) {
      setPopupShown(false);
      localStorage.setItem("popupShown", "true");
    } else {
      setPopupShown(true);
    }
  }, []);



  useEffect(() => {
    const check = localStorage.getItem("visit");
  setChecked(!!check); 
  if (!check && !visited) { // Only run if not visited and not checked
    const data = { count: "yes" };
    axios.post(`/traffic`, data).then((res) => {
      if (res.data) {
        localStorage.setItem("visit", "true"); // Store visit flag in localStorage
        setVisited(true); // Update visited state after successful visit
      }
    });
  }
  }, [visited,checked ]);


  const handleButtonClick = (buttonId) => {
    setvalue(buttonId);
    setActiveButton(buttonId);
	  setPropChange(buttonId);
  };

    function SubscribeEmailGet(e) {
    e.preventDefault();
    let data = { 'subemail': subemail, 'pageUrl':"Subscribe" };
    if (subemail) {
      if (isChecked) {
        axios.post(`/getemailforsub`, data).then((res) => {
          if (res.status === 200) {
            const params = new URLSearchParams(data).toString();
               window.location.href = `/thankyou?${params}`;
          } else {
            Swal.fire({
              title: 'Notice!',
              text: 'You are already subscribed!',
              icon: 'info',
              confirmButtonText: 'Ok'
            });
          }
        }).catch((error) => {
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred while processing your request. Please try again later.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
      else {
        // Handle cases where email is not provided
        if (!subemail) {
          Swal.fire({
            title: 'Error!',
            text: 'Please provide a valid email address.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        // Handle cases where checkbox is not checked
        if (!isChecked) {
          Swal.fire({
            title: 'Warning!',
            text: 'Please click on Agree for further processing.',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }
    }


  }

  
  function Careerpage() {
    
	   window.open('/testimonials', '_blank');
  }


  function whichPage(e) {
    const page = e.target.value
    setselectdrop(page)
  }

  // **************Blog Data Fetch****************
  

  // **************HOme Data Fetch****************
  useEffect(() => {
    const homedata = { "tablename": "homepagedb" }
    axios.post(`/staticdata`, homedata).then((res) => {
      setdata(res.data)
		
		
    })
  }, []);


  function Saveblogid(e) {
    const blogid = e.target.id
    localStorage.setItem("blogid", blogid)
    navigate("/blogfirst")
  }



  function extractTextFromHtml1(html, tag, index, charLimit) {
    const match = html.match(new RegExp(`<${tag}.*?>(.*?)<\/${tag}>`, 'g'));
    if (match && match.length > index) {
      const plainText = match[index].replace(new RegExp(`<${tag}.*?>|<\/${tag}>`, 'g'), '');
      return plainText.length > charLimit ? plainText.substring(0, charLimit) + '...' : plainText;
    }
    return '';
  }

  const [popuptrue, setpopuptrue] = useState(false); 
 

useEffect(()=>{

 
    if (selectdrop == "Sale") {
      axios.get(`/allprosale`).then((res) => {
        setAddressdata(res.data);
        setLoading(false);
      })
        .catch((error) => {
          console.error("Error fetching data from the server:", error);
        });
    }
    else {
      axios.get(`/allprorent`).then((res) => {
        setAddressdata(res.data);
        setLoading(false);
      })
        .catch((error) => {
          console.error("Error fetching data from the server:", error);
        });
    }

},[selectdrop]);




var filteredData;
const uniquePropertyNames = new Set();

// Filter jsonData to retain only unique entries based on PropertyName
const uniqueJsonData = addressdata.filter(item => {
  // Convert PropertyName to lowercase for case-insensitive comparison
  const propertyName = item.Property_Name.toLowerCase();
  // If PropertyName is already in the Set, return false to filter out the duplicate
  if (uniquePropertyNames.has(propertyName)) {
    return false;
  }
  // If PropertyName is not in the Set, add it and return true to keep the item
  uniquePropertyNames.add(propertyName);
  return true;
});

// Filter the uniqueJsonData based on filterLocation
if (filterLocation.length) {
  filteredData = uniqueJsonData.filter(item =>
    item.Property_Name.toLowerCase().includes(filterLocation.toLowerCase()) ||
    item.Emirate.toLowerCase().includes(filterLocation.toLowerCase()) ||
    item.Community.toLowerCase().includes(filterLocation.toLowerCase())
  );
}
  const handleItemClick = (selectedItem) => {

    setFilterLocation(selectedItem);

  };
  return (
    <>
	  <Helmet>
	           <title>Raine and Horne | Real Estate Company in Dubai</title>

 <meta name="description" content={" Discover luxury living in Dubai with Raine & Horne. Browse our exclusive collection of properties, from stunning apartments to lavish villas, and find your perfect home in the heart of the city. Whether you're seeking a prime investment opportunity or your dream residence, trust us to guide you every step of the way in the dynamic Dubai real estate market"|| 'Default Description'} />

</Helmet>

{!popupShown ?
<Popup/>
:
<div></div>

    }
    

      <div className="">
        {data.map((item, index) => {

          return (
            <>


              <main id="main">
          
  <div style={{height:"82px"}}></div>
                {/* *************Header******************** */}

                <div className="container-fluid sec-2" id="bannerimage" style={{ backgroundImage: 'url(images/1701232494735.webp)'}}>
                  <div style={{width:"100%"}} >  
                    <h2 className="home_banner_head" id="unlock"> {item.sec1heading}</h2>
                    <div className="search-engine-main">
                      <div className="row engine-code">
                        <div className="mx-1 my-1 col-12 col-sm-12 col-md-2 property-option">
                          <select onChange={whichPage} name="" className="class-select-menu select-input-field" id="" style={{ color:"#383838",outline: "none", border:"none" }}>
                            <option value="Sale">Buy</option>
                            <option value="Rent">Rent</option>
                          </select>
                        </div>
                        <div className=" col-12 col-sm-12 col-md-7  mx-1 my-1 property-option">
                          <input style={{fontSize:"18px",fontFamily:"Raine-HorneLight"}} type="text" value={filterLocation} onChange={handlefieldChange} className="search-engine-input" placeholder="Search..." />
						      {filteredData && (
        <div className="dropdown dropdownwidth" style={{marginTop:"10px",position:"absolute", zIndex:"1", width: "-webkit-fill-available", boxShadow: "rgb(204, 204, 204) 0px 0px 10px 0px" }}>
          {/* Dropdown content goes here */}
          <ul className="dropdown-search-engine-list" style={{background:"#fff",maxHeight:"200px",overflowY:"scroll"}}>
          {filteredData.map((item, index) => (
              <div
                key={index}
                style={{ marginLeft: "15px", cursor: "pointer",marginTop:"5px" }}
                onClick={() => handleItemClick(`${item.Property_Name}, ${item.Emirate}, ${item.Community}`)}
              >
                {item.Property_Name}, {item.Emirate}, {item.Community}
              </div>
            ))}
          
          </ul>
        </div>
      )}
                        </div>
                        <div className=" col-12 col-sm-12 col-md-2 mx-1 my-1 property-option prop-faltu">
                          <button className="search-engine-btn" onClick={SearchFilter}>Search</button>
                        </div>
                      </div>
                    </div>
                    {/* Search Engine Design on Home Page End */}
                  </div>
                </div>



                {/* *************RENT AND SALE******************** */}
                <div className="container our-property">
                  <h2 id="explore-property" className="center" >{item.sec2heading}</h2>


                  <ul className="nav nav-pills mt-5 " id="pills-tab" role="tablist">

                    <div className="centered-container" style={{ margin: "auto" }} >
                      <button className={`salerentbtn ${activeButton === "Sale" ? "active" : ""}`}
                        style={{color:"#383838",border:"none", backgroundColor: activeButton === "Sale" ? "#fcaf17" : "" }} id="Sale" onClick={() => handleButtonClick("Sale")}>
                        Buy
                      </button>
                      <button className={`salerentbtn ${activeButton === "Rent" ? "active" : ""}`} style={{color:"#383838",border:"none", backgroundColor: activeButton === "Rent" ? "#fcaf17" : "" }} id="Rent"
                        onClick={() => handleButtonClick("Rent")}>
                        Rent
                      </button>
                    </div>
                  </ul>
				  
				  
				   <Suspense fallback={<div>Loading...</div>}>
				   <HomeSlider propChange={propChange}/>
				   </Suspense>
				  
				  
				  
                </div>

                {/* *************FORM******************** */}
                <div className="container property-form " id='new-form-change' style={{ padding: "40px 10px", width: "87%" }}>
                  <div className="container">

                    <div className="form-title" style={{ padding: "0px !important" }}>
                      <h2 style={{color:"#504d4f" }} id="" className="center">{item.sec3heading}</h2>
                      <p id="seel-property-para-thinking">{item.sec3description} </p>
                    </div>

                  </div>

                  <div className="container">
                    <div className="row" style={{ justifyContent: "space-evenly",marginTop:"30px" }}>
                      <div className="col-12 col-sm-12 col-md-6	col-lg-7 col-xl-7 padd768">

                        <Suspense fallback={<div>Loading...</div>}> <HomeForm /></Suspense>
                      </div>

                      <div className="col-12 col-sm-12 col-md-5	col-lg-5 col-xl-5">
                        <LazyLoadImage src={`uploads/${item.sec3image}`} alt="img" className="img-fluid" id="homeimg" style={{ height: "460px" }} />
                      </div>

                    </div>
                  </div>
                </div>

                {/* *************Hoiday Homes******************** */}
                <div className="container-fluid col-md-12 sec-4 d-flex align-items-center paradise-style" id="escape_holiday" style={{ backgroundImage: `url(/uploads/${item.sec4bgimage})`}}>
  <div style={{  textAlign: "center", width: "100%" }}>
    <h1 className="main-banner-heading" style={{ marginTop: "0px" }}>{item.sec4heading}</h1>
    <h3>{item.sec4subheading}</h3>
    <div className="center">
      <a target="blank" href="https://www.rhvacations.ae">
        <button type="button" className="btn btn-explore" href="" style={{letterSpacing:"0px"}}>
          {item.sec4button}
        </button>
      </a>
    </div>
  </div>
</div>


                {/* ***************OVERVIEW***************** */}
                <div className="container counter-sec overview-change-home" id="no_div">
  <div className="row g-1 .overview-change" style={{ justifyContent: 'space-between' }}>
    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <LazyLoadImage src={`/uploads/${item.sec5image}`} alt="Los Angeles" className="img-fluid" style={{ width: "100%",height:"100%" }} />
    </div>

    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center overview-change">
      <div>
        <div className="row" id="norow1" style={{paddingTop:"0px !important"}}>
          <div className="box-145" style={{ padding: "0px auto 5px" }}>
            <h4 className="no_headings">{item.sec5number1}</h4>
            <p className=" no_para">{item.sec5numberdiscription1}</p>
          </div>
        </div>

        <div className="row" id="norow2">
          <div className="box-145" style={{ padding: "0px auto 5px" }}>
            <h4 className="no_headings">{item.sec5number2}</h4>
            <p className=" no_para">{item.sec5numberdiscription2}</p>
          </div>
        </div>

        <div className="row" id="norow3">
          <div className="box-50 box-145" style={{ padding: "0px auto 5px" }}>
            <h4 className="no_headings">{item.sec5number3}</h4>
            <p className=" ">
              {item.sec5numberdiscription3}
              {/* COUNTRIES <br />&amp; TERRITORIES */}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


                {/* *************Our Story******************** */}
                <div className="container content-sec" id="our-story-main-div" >
                  <div className="row " >
                    <div className="col-md-12 our_story_alter">
                      <h3 style={{margin:"0px",color:"#504d4f",textAlign:"center"}} className="all-main-heading our_story">{item.sec5heading}</h3>
                      <p className="all-para our_story_para" style={{width:"100%"}}>
                        {item.sec5discription}
                      </p>


                      <button type="button" className="btn btn-news" onClick={GoTOAboutUS} style={{letterSpacing:"0px"}}>Read More</button>
                    </div>

                  </div>
                </div>
                {/* *************WHY CHOOSE US******************** */}

                <div className="container why-choose">

                  <div className="row main-why-choose">
                    <div className="col-md-12 colclass12" >
                      <h2 className="all-main-heading " id="why_heading_id" style={{ textAlign: 'center',color:"#504d4f" }}>{item.sec6heading}</h2>
                    </div>
                  </div>

                  <div className="row" id="why-chhose-us-main-divs">
                    <div className="col-md-4 colclass4">
                      
                      <LazyLoadImage src={`uploads/${item.sec6logo}`} alt="Los Angeles" className="img-fluid" style={{ width: "100px" }} />
                      <h4 className="grey pb-2 all-sub-heading" >{item.sec6logoheading}</h4>
                      <p className="all-para textPaddingWhyChoose">{item.sec6logodiscription}</p>
                    </div>

                    <div className="col-md-4 colclass4">
                      
                      <LazyLoadImage src={`uploads/${item.sec6logo2}`} alt="Los Angeles" className="img-fluid" style={{ width: "100px" }} />
                      <h4 className="grey pb-2 all-sub-heading">{item.sec6logoheading2}</h4>
                      <p className="all-para textPaddingWhyChoose">
                        {item.sec6logodiscription2}
                      </p>
                    </div>

                    <div className="col-md-4 colclass4">
                      
                      <LazyLoadImage src={`uploads/${item.sec6logo3}`} alt="Los Angeles" className="img-fluid" style={{ width: "100px" }} />
                      <h4 className="grey pb-2 all-sub-heading">{item.sec6logoheading3}</h4>
                      <p className="all-para textPaddingWhyChoose">
                        {item.sec6logodiscription3}
                      </p>
                    </div>
                  </div>

                </div>


                {/* *************TESTIMONIALS******************** */}

                <div className="container-fluid testimonials">
				<div className="testimonial_wrap">
                  <div className="row">
                    <div className="col-md-12">

                      <h2 className="all-main-heading" style={{ textAlign: 'center',color:"#504d4f" }}>{item.sec7heading}</h2>
                      <div className="rating">
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                      </div>
                    </div>
                  </div>

                  {/* *************Testomonials*************** */}
                  <div className="row">
                    <Suspense fallback={<div>Loading data...</div>}>
                  <Testomonial />
                 </Suspense>
                  </div>
                  <div className="row">
                    <div className="col-md-12 view-more">
					
					                     <button type="button" onClick={Careerpage} className="btn btn-news" style={{letterSpacing:"0px"}} aria-label={item.sec7button}>{item.sec7button}</button>
					
 
                    </div>
                  </div>
				  </div>
                </div>

                {/* *************News & Insight*************** */}
                <div className="container news">
                  <div className="row news-css-change">
                    <div className="col-md-12">
                      <h2 className="all-main-heading" style={{ textAlign: 'center', color:"#504d4f"  }}>{item.sec8heading}</h2>
                    </div>
                  </div>

                
				<Suspense fallback={<div>Loading data...</div>}>
                  <HomeBlog/>
                  </Suspense>


                  <div className="row">
                   
                      <div className="col-md-12 view-more">
					   <a href='/blog' target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer">
                        <button type="button" className="btn btn-news" style={{letterSpacing:"0px"}}>View All Posts</button>
						 </a>
                      </div>
                   
                  </div>
                </div>

                {/* *************STAY INFORMED*************** */}
                <div className="container-fluid newsletter" style={{ backgroundImage: `url(/uploads/${item.sec9bgimg})` }}>
                  <div className="container">

                    <h1 id="all-main-heading" style={{margin:"0px"}}>{item.sec9heading}</h1>
                  <div id="stay-text" style={{textAlign:"center", color:"#fff"}}>{renderWithLineBreaks(item.sec9discription)}</div>

                    <form className="validated">
                      <div className="row ">
                        <div className="col-md-9 sectionpaddingzero">
                     <input 
        onChange={handleEmailChange}
        type="email" 
        className="form-control" 
        id="emailAddress" 
        placeholder="Email address" 
        name="emailAddress" 
        style={{ borderRadius: "0px" ,height: "50px"}} 
        required 
      />
      {!isValidEmail && (
        <p style={{ color: 'red', fontSize: '14px', margin: '5px 0 0' }}>
          Please enter a valid email address.
        </p>
      )}
						  
						   
                          <div className="checkandmate" style={{margin:"6px auto"}}>


                            <input style={{ marginRight:"10px !important"}} type="checkbox" id="myCheck" name="remember" checked={isChecked} onChange={handleCheckboxChange} required />
							
                            <label style={{marginTop:"1px", paddingLeft:"10px !important"}} className="form-check-label" htmlFor="myCheck" id="check-text" >
                              &nbsp;&nbsp;&nbsp;I agree to the terms & privacy policy.
                            </label>
                          </div>
                        </div>

                        <div className="col-md-3 sub_button">
                          <button type="submit" className="bg-light-grey " style={{ borderRadius: "0px",marginTop:"0px" }} onClick={SubscribeEmailGet}>{item.sec9button}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </main>
            </>
          )
        })}

      
           <Suspense fallback={<div>Loading...</div>}> <WhatsappChat /></Suspense>
        {/* Owl Carousel */}
      </div>
    </>
  );
};

export default Main;
