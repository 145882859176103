import React, { useEffect, useState ,useContext, Suspense} from 'react'
import '../styles/offplan.css'

const WhatsappChat = React.lazy(() => import('./Notification/Whatapp'));
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SearchContext from '../Context/Context1';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GodsPlan = () => {
	
	 useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
    const getContext = useContext(SearchContext);
    const [data,setData]=useState([]);
	const [text, setText]=useState('');
    const [propertyname,setPropertyname]=useState('');
    useEffect(()=>{
    axios.get("/getnewly").then((res)=>{
        
        setData(res.data);
    });
    },[]);
	
	useEffect(()=>{
		axios.get('/newdevtext', text)
		.then((response)=>{
			setText(response.data[0]);
			//console.log(response.data);
		}).catch((err)=>{
			console.log('err fetching new dev text',err);
		});
	},[]);

    function handleNavigate(id,name){
        setPropertyname(name)
		
		
     localStorage.setItem("internalpageid",id)
     localStorage.setItem("pagename",name)

    // getContext.setPageid(id);
    getContext.setPagename(name);
    }

	console.log()
const name=localStorage.getItem('pagename');
	//console.log(propertyname);
    
  return (
    <>
    
    
   
	              <Helmet>
                <title>New Development Properties in Dubai | Raine and Horne | Real Estate Company</title>
                <meta name="description" content={'With one of our carefully chosen newly built homes in Dubai, live in the lap of luxury. Every house, from luxurious estates on the beaches to stylish urban apartments, is carefully built to meet the most demanding standards of modern living.' || 'Default Description'} />
           
            </Helmet>
    <div className='container'>
	     <div style={{height:"90px"}}></div>
	  <div className="j5dj8e">{text.newtext}</div>
    <div className='row mt-2 newdev-change-margin'>
        {data.map((item,index)=>{
         
	    const relativePath = item.bannerimage.substring(item.bannerimage.indexOf('/uploads/'));
	
            return(
                <div className='col-12 col-xl-4 col-lg-4  col-sm-12 my-2 pt-1 mymediaclass' style={{display: item.live ? "":"none"}}>
      <Link to={`/${item.pagelink}`}>

            <div className="mar-sm-30" style={{boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={()=>handleNavigate(item.id, item.pagelink)}>
                <div><LazyLoadImage src={`/uploads/${item.bannerimage}`} alt="New Image"  width="100%" height="240px"/></div>
                <div className='m-2 i4g66fds4'>
                    <div className='dveti d-flex justify-content-between' style={{color:"#4e5150"}}><span>{ item.propertyname}</span><span style={{marginLeft:"20px"}}>{item.developername}</span></div>
                    <p className="huye" style={{fontWeight:"500",color:"#212529"}}>{item.propertylocation}</p>
                    <p className="dwet" style={{color:"#4e5150",lineHeight: "22px",marginTop: "15px"  }}>Starting From AED {item.price}</p>
                    <p className="joy" style={{fontWeight:"500",color:"#212529"  }}>{item.complete}</p>
                    
                </div>
                <button className  ='p-1 hovon'>Know More</button>
            </div>
                </Link>

            </div>
            );
        })}
</div>
<Suspense fallback={<div>Loading...</div>}>
  <WhatsappChat/>
</Suspense>

</div>
</>
  )
}

export default GodsPlan;