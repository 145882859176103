import React, { useState, useEffect } from "react";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useNavigate } from "react-router";
import Form from "react-bootstrap/Form";
import "../../styles/main/popup.css";

import { Dropdown } from "react-bootstrap";
import Swal from 'sweetalert2';
import Countries from "./CountryInfo";

import axios from 'axios';

function Popup() {
  const naviagte = useNavigate();
  const [show, setShow] = useState(false);
  const [Name, setName] = useState('');
  const [email, setemail] = useState('');
const [phoneNumber, setPhoneNumber] = useState("");
	const [selectedCountry, setSelectedCountry] = useState(Countries[1]);
  const [SelectedOptionval, setSelectedOptionval] = useState('');
  const [SelectedValue, setSelectedValue] = useState('');
	 const dialCode = selectedCountry.phone[0];


	 const handleCountrySelect = (item) => {
    setSelectedCountry(item);
  };


  const options = [
    { label: "Buy", value: "Buy" },
    { label: "Rent", value: "Rent" },
    { label: "Sell", value: "Sell" },
    { label: "List your property", value: "List your property" },
    { label: "Property management", value: "Property management" },
    { label: "Holiday homes", value: "Holiday homes" },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const handleClose = () => setShow(false);


  const customStyles = {
    control: (provided) => ({
      ...provided,
      textAlign: "left",
      borderRadius: '0px',
  
    
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left", // Align the menu to the left
      overflowY: 'auto',
      height:"100px"
    
    }),
  };
  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setSelectedOptionval(selectedOption.value);
  };
	

	
	const [isValidEmail, setIsValidEmail] = useState(true);
	function SubNewDataSub(e){
	const email = e.target.value;
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
	setemail(email);
	}
	
	
	const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Remove any non-digit characters
    const sanitizedValue = value.replace(/\D/g, '');
    setPhoneNumber(sanitizedValue);
  };
//country validation
const validatePhoneNumber = (number, countryCode) => {
	const fullNumber = `${countryCode}${number}`;
    const phoneNumber = parsePhoneNumberFromString(fullNumber);
    return phoneNumber ? phoneNumber.isValid() : false;
};

 function SubsctibeButton(e) {
	  e.preventDefault();
    if (Name && email && phoneNumber && SelectedOptionval) {
		  if (!validatePhoneNumber(phoneNumber, dialCode)) {
        Swal.fire({
          title: 'Warning!',
          text: 'Please enter a valid phone number!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        return;
      }
      let data={
		        "Name":Name, 
				"email":email, 
				"Phone":phoneNumber, 
				"SelectedOptionval":SelectedOptionval, 
				"dialcode":dialCode
			   };
		console.log('data', data);
      axios.post('/popupsubscribe', data).then((res) => {
		
        if(res.status===200){
         naviagte('/subscribeus')
        }
      })
    }
    else {

      Swal.fire({
        title: 'Warning!',
        text: 'Please Enter Valid Data!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    
  }
}
  return (
    <>
     
      <Modal show={show} onHide={handleClose} id="modelmain" style={{borderRadius : "0px"}}>
        <Modal.Header closeButton>
          <Modal.Title id="popheading">
            Unlock Exclusive Dubai Property Insights!
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <p style={{ margin: "0", fontSize: "14px !important" }} id="subheading">
            Join our VIP list for early access to the best property listings,
            market trends, and special offers.
          </p>

          <Form className="mt-3 row popup_form_update home-page-popup" >
            <Form.Group className="mb-3 col-12 popup-forminput" controlId="formBasicEmail">
              <Form.Control type="email" style={{ borderRadius: "0px",height:"50px" }} placeholder="Full Name*" onChange={(e)=>setName(e.target.value)} required />
            </Form.Group>

            <Form.Group className="mb-3 col-12 popup-forminput" controlId="formBasicEmail">
              <Form.Control type="email" style={{ borderRadius: "0px",height:"50px" }} placeholder="Email*" onChange={SubNewDataSub} required/>{!isValidEmail && (
        <p style={{ color: 'red', fontSize: '14px', margin: '5px 0 0',textAlign: "left" }}>
          Please enter a valid email address.
        </p>
      )}
            </Form.Group>

           <div className="form-group popup-forminput" style={{ position: "relative"}}>

                            <Dropdown style={{ position: "absolute", borderRight: "1px solid #ddd", height: "50px", width:"86px" }}>

                              <Dropdown.Toggle className="codedrop" variant="transparent" id="dropdown-basic" style={{ width: "100%", border: "none", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius:"0px" }}>
  <div style={{ display: "flex", alignItems: "center" ,justifyContent: "space-between"}}>
    <img src={selectedCountry.image} alt="" style={{ height: "30px" }} />
    <p style={{ marginTop:"3px", display:"none" }}>{dialCode}</p>
  </div>
  <span className="caret">
	  <img className="arrow-alteration" src="/images/arrow-icon.svg" alt="" />
	  </span>
</Dropdown.Toggle>



                              <Dropdown.Menu style={{ height: "200px", overflow: "auto" }}>

                                {Countries.map((item) => {
                                  return (

                                    <Dropdown.Item onClick={() => handleCountrySelect(item)}>
                                      <div id="image-code">
                                        <img src={item.image} alt={item.name} style={{ width: "30px", height: "32px" }} />
                                        <p className="ml-5 mt-1">{item.name}{item.phone && item.phone.length > 0 ? item.phone[0] : "N/A"}
                                        </p>
                                      </div>
                                    </Dropdown.Item>

                                  );
                                })}
                              </Dropdown.Menu>

                            </Dropdown>

                            <input onChange={(e) => setPhoneNumber(e.target.value)} type="phone" className="form-control contcolor" placeholder="Phone No.*" value={phoneNumber} required style={{ left: "160px", paddingLeft: "165px" }} />{!validatePhoneNumber(phoneNumber, dialCode) && phoneNumber.length > 0 && (
  <p style={{ color: 'red', fontSize: '14px', margin: '5px 0 0', textAlign: "left" }}>
    Please enter a valid phone number.
  </p>
)}
                          </div>

            <Form.Group className="mb-3 col-12 popup-forminput" controlId="formBasicEmail" style={{height:"50px" }}>
              <Select options={options} placeholder="What can we help you with? " isSearchable={true} value={SelectedValue} onChange={handleChange} styles={customStyles} id="op"/>
            </Form.Group>
          </Form>

          <div id="two-para" className="mt-3">

            <ul style={{ listStyleType: "disc", marginLeft: "0" }}>
              <li style={{ listStyleType: 'none', fontSize: "14px !important" }}><p id='summs'>We respect your privacy. Your information is safe with us and will never be shared with third parties.Be the first to know about new properties on the market.</p></li>
             
            </ul>

          </div>


          
          <Button className="pop-subs" onClick={SubsctibeButton} id="popup_btn">
            Subscribe Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup;
